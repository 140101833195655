<template lang="pug">
om-table.brand-full-table(
  :items="accountMembers"
  :columns="columns"
  :columnClasses="columnClasses"
  :allItemsCount="accountMembersCount"
  :selectable="false"
  :pagination="pagination"
  :show-header="false"
  @sortingChange="$emit('sortingChange', $event)"
  @paginationChange="$emit('paginationChange', $event)"
)
  template(slot="accountInfo" slot-scope="{ rowData }")
    template(v-if="rowData.row.firstName && rowData.row.lastName")
      .font-weight-bold {{ getFullNameByLocale(rowData.row.firstName, rowData.row.lastName) }}
      .brand-full-table-extendable-row-text.font-weight-normal {{ rowData.row.email }}
    div(v-else) {{ rowData.row.email }}
  template(slot="status" slot-scope="{ rowData }")
    template(v-if="isExpired(rowData.row.expiration)")
      span.mr-2.brand-btn.brand-btn-danger.brand-btn-danger-sm.pointer-events-none {{ $t('expired') }}
      a.brand-link.font-size-0--75(
        href="javascript:void(0)"
        @click="resendInvitation(rowData.row._id)"
      ) {{ $t('resend') }}
    .brand-btn.brand-btn-warning.brand-btn-danger-sm.pointer-events-none(
      v-else-if="rowData.row.expiration"
    ) {{ $t('pending') }}
    //- div(v-else) {{ $t('accepted') }}
  template(slot="settings" slot-scope="{ rowData }")
    .d-flex.remove-setting-box.cursor-pointer
      i.fas.fa-times.remove-setting(@click="removeMember(rowData.row.email)")
</template>

<script>
  import dateFormat from '@/mixins/dateFormat';
  import fullName from '@/mixins/fullName';
  import REMOVE_MEMBER from '@/graphql/RemoveMember.gql';
  import RESEND_INVITATION from '@/graphql/ResendInvitation.gql';

  export default {
    mixins: [dateFormat, fullName],

    props: {
      accountMembers: { type: Array, require: true },
      accountMembersCount: { type: Number, require: true },
    },

    data() {
      return {
        pagination: { page: 1, limit: 30 },
        columnClasses: {
          accountInfo: 'text-left flex-grow-1',
          status: 'd-flex flex-nowrap align-items-center flex-grow-0',
          settings: 'flex-grow-0 ml-4',
        },
      };
    },

    computed: {
      columns() {
        return [
          { header: '', key: 'accountInfo', sortable: false },
          { header: this.$t('status'), key: 'status', sortable: false },
          { header: this.$t('settings'), key: 'settings', sortable: false },
        ];
      },
    },

    methods: {
      isExpired(expiration) {
        return new Date(expiration) < new Date();
      },

      resendInvitation(id) {
        this.$apollo
          .mutate({
            mutation: RESEND_INVITATION,
            variables: {
              id,
            },
          })
          .then(({ data: { resendInvitation } }) => {
            if (resendInvitation) {
              this.$notify({
                type: 'success',
                text: this.$t('notifications.invitationResendSuccess'),
              });

              this.$emit('refetch');
            } else {
              // eslint-disable-next-line
              throw false;
            }
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.invitationResendError'),
            });
          });
      },

      removeMember(email) {
        this.$apollo
          .mutate({
            mutation: REMOVE_MEMBER,
            variables: {
              email,
            },
          })
          .then(({ data: { removeMember } }) => {
            if (removeMember) {
              this.$notify({
                type: 'success',
                text: this.$t('notifications.removeSuccess'),
              });

              this.$emit('refetch');
            } else {
              // eslint-disable-next-line
              throw false;
            }
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.removeError'),
            });
          });
      },
    },
  };
</script>
