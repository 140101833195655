<template lang="pug">
.container-fluid.px-3
  .row.mb-4
    .col
      om-heading.mt-1(h1) {{ $t('accountSharing') }}
  .row
    .col-12.col-lg-10
      .d-flex.align-items-start.mt-3.mb-5.pb-4.bb-1
        om-input#inviteEmailAddress.w-20.mr-4(
          v-model="inviteEmailAddress"
          ref="inviteEmailAddress"
          type="email"
          @keyup.enter="invite"
          :placeholder="$t('inviteByEmailPlaceholder')"
        )
          template(
            slot="error"
            v-if="$v.inviteEmailAddress.$error && !$v.inviteEmailAddress.required"
          )
            span {{ $t('error.field.required') }}
          template(
            slot="error"
            v-if="$v.inviteEmailAddress.$error && !$v.inviteEmailAddress.isCoolEmail"
          )
            span {{ $t('error.field.email') }}
        om-button(
          primary
          :class="{ loading: waitForResponse }"
          :disabled="waitForResponse"
          @click="invite"
        ) {{ $t('invite') }}
  .row
    .col-12.col-lg-10.position-static
      loading-logo(v-if="loading")
      account-sharing-table(
        v-else
        :accountMembers="accountMembers"
        :accountMembersCount="accountMembers.length"
        @refetch="fetchSharedAccountData"
      )
</template>

<script>
  import AccountSharingTable from '@/components/Tables/AccountSharing.vue';
  import GET_ACCOUNT_MEMBERS from '@/graphql/GetAccountMembers.gql';
  import ADD_INVITATION from '@/graphql/AddInvitation.gql';
  import { email, required } from 'vuelidate/lib/validators';

  export default {
    components: {
      AccountSharingTable,
    },

    data() {
      return {
        loading: true,
        inviteEmailAddress: '',
        accountMembers: [],
        waitForResponse: false,
      };
    },

    validations: {
      inviteEmailAddress: {
        required,
        isCoolEmail(v) {
          return email(v.toLowerCase());
        },
      },
    },

    async created() {
      this.loading = true;
      await this.fetchSharedAccountData();
      this.loading = false;
    },

    methods: {
      async fetchSharedAccountData() {
        try {
          const {
            data: { accountMembers },
          } = await this.$apollo.query({
            query: GET_ACCOUNT_MEMBERS,
          });

          this.accountMembers = accountMembers || [];
        } catch (e) {
          console.log(e);
        }
      },

      async invite() {
        this.$v.$touch();
        const inputValidation = this.$refs.inviteEmailAddress.validate();
        if (this.$v.$invalid || !inputValidation) {
          return;
        }

        this.waitForResponse = true;
        try {
          const {
            data: { addInvitation },
          } = await this.$apollo.mutate({
            mutation: ADD_INVITATION,
            variables: {
              email: this.inviteEmailAddress.toLowerCase(),
            },
          });

          if (addInvitation.success) {
            this.$notify({
              type: 'success',
              text: this.$t('notifications.invitationSuccess'),
            });

            this.inviteEmailAddress = '';
            this.$v.$reset();
            this.$refs.inviteEmailAddress.$v.$reset();
            this.fetchSharedAccountData();
          } else if (addInvitation.message === 'alreadyInvitedOrAdded') {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.alreadyInvitedOrAdded'),
            });
          } else if (addInvitation.message === 'recentlySent') {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.recentlySent'),
            });
          } else if (addInvitation.message === 'dailyLimit') {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.dailyLimit'),
            });
          } else if (addInvitation.message === 'onlyOwnerCanInvite') {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.onlyOwnerCanInvite'),
            });
          }
        } catch (e) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.invitationError'),
          });
          console.log(e);
        }

        this.waitForResponse = false;
      },
    },
  };
</script>
